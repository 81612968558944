.logo-wrap {
  text-align: center;
  padding:20px;

}
.vertical-align {
 
  height:calc(100vh - 138px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth-bg {
  background-color: #47269b;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.bg-img {
  background: url("../imgs/auth-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
  height: 100vh;
}
.auth-wrap {
  background-color: #ffff;
  border-radius: 6px;
}
.inner-wrap {
  padding: 35px 20px;
}
.step {
  color: #47269b;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}
.step-content {
  background-color: #c2b6e1;
  padding: 3px 7px;
  border-radius: 30px;
}
.auth-title {
  font-family: "Inter", sans-serif;
  font-size: 32px;
  font-weight: 700;

  text-align: center;
  color: #47269b;
}
.title-wrap {
  margin-bottom: 40px;
}
#custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  background-image: url("../imgs/arrow.svg");
  background-repeat: no-repeat;
  background-position: right 3% center;
  padding-right: 20px !important;
}

#custom-select {
  padding: 15px;
  border: 1px solid #d1c8e8;
  border-radius: 5px;
  width: 100%;
  /* margin-bottom: 20px; */
}
.form-label {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}
.save-btn {
  background-color: #47269b;
  width: 100%;
  border-radius: 30px;
  font-family: "Inter";
  font-size: 18px;
  font-weight: 700;
  padding: 10px;
  text-align: center;
  outline: transparent;
}
.auth-desc {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 500;
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 0;
}

.phone-input {
  display: flex;
  align-items: center;

}

.country-code {
  flex: 1;
  padding: 10px;
  border: 1px solid #d1c8e8;
  border-radius: 5px 0 0 5px;
  font-size: 16px;
  width: 100px;
}

.phone-number {
  flex: 3;
  padding: 10px;
  border: 1px solid #d1c8e8;
  border-radius: 0 5px 5px 0;
  font-size: 16px;
}

.phone-number:focus {
  outline: none !important;
}

.login-email {  
  padding: 10px;
  border: 1px solid #d1c8e8;
  border-radius: 0 5px 5px 0;
  font-size: 16px;
  width: 100%;
}

.login-email:focus {
  outline: none !important;
}

.country-code:focus {
  outline: none !important;
}
.terms-wrap {
  margin-top: 20px;
}
.terms {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 0;
  text-align: center;
}
.terms-link {
  color: #47269b;
}
.otp-input input {
  width: 100% !important;
  height: 60px;
  border: 1px solid #47269b;
  border-radius: 8px;
  margin-bottom:6px;
}
.resend-btn{
  color:#47269b;
  background-color:transparent !important;
  border:none;
  outline: none !important;
  margin-bottom:20px
}
.progress-wrap{
  text-align: center;
  margin-top:20px;
}
.btn-wrap{
  margin-top:20px;
  margin-bottom:20px
}
.error-text{
  color:red;
  font-size:12px;
}
.progressbar{
  padding: 20px 100px;

}
.CircularProgressbar-text {
  transform:  translate(-25px, 11px);
  color:#47269b;
}
.img-error-wrap{
  display:flex;
  align-items: center;
  justify-content: center;
  
}
.try-wrap{
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top:40px;
}
.try-again{
font-family: 'Inter',sans-serif;
font-size: 18px;
font-weight: 700;
color:#47269b;
margin-bottom: 0;

}
.arrow-wrap{
  padding-left:20px;
}
.get-started{
  position:relative;


}
.arrow-right{
  position:absolute;
  top: 14px;
  right: 20px;
}