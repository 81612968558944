@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');
* {
  font-family: 'Inter', sans-serif;
}
.page-wrap {
  height: auto;
  background: #F2F6FF;
}
.questions-header-wrap {
  background: #47269B;
}
.question-header {
  text-align: center;
  padding: 20px 20px 40px 20px;
}
.qheader-txt1 {
  color: #ffffff;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 0;
}
.qheader-txt2 {
  color: #C3B6F6;
  font-size: 16px;
  font-weight: 500;
}
.questions-wrap {
  background: #ffffff;
  padding: 10px;
}
.question-count-wrap {
  background: #ffffff;
  margin-top: -50px;
  padding: 14px 8px;
  border-radius: 8px;
  box-shadow: 0 0 4px #D5DFF6;
}
.question-num span {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #D9D1EE;
  color: #ffffff;
  font-size: 22px;
  font-weight: 500;
}
.answered span {
  background: #47269B;
}
.question-list {
  display: flex;
  align-items: center;
  column-gap: 10px;
  justify-content: space-between;
  padding: 20px 10px 0 10px;
}
.question-list p {
  font-size: 18px;
  font-weight: 500;
}
.question-list p span {
  color: #47269B;
  font-weight: 700;
}
.timer-wrap p {
  font-weight: 500;
  background: #47269B;
  border-radius: 8px;
  padding: 10px 20px;
}
.timer-wrap p span {
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  padding: 2px;
}
.answer-wrap {
  padding: 20px 0;
}
.answer-wrap .options,
.review-quest-wrap {
  cursor: pointer;
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 4px #D5DFF6;
  margin-bottom: 14px;
}
.q-num {
  font-size: 18px;
  font-weight: 700;
  color: #47269B;
}
.q-txt {
  font-size: 16px;
  font-weight: 500;
}
.option-inner {
  display: flex;
  align-items: flex-start;
  column-gap: 10px;
  font-size: 16px;
  font-weight: 500;
}
.answer-wrap .options:hover {
  /* background: #c5fbce; */
  background: #dce4e6;
  cursor: pointer;
}
/* .answer-wrap .options:hover,
.answer-wrap .options:hover .option-letter {
  color: #ffffff;
} */
.option-inner .option-letter {
  color: #47269B;
  font-size: 18px;
  font-weight: 700;
}
.options-wrap {
  position: relative;
}
.option-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
input:checked + label,
input:checked + label .option-letter {
  /* background: #65D176 !important; */
  background: #ADD8E6 !important;
  color: #ffffff;
}
.answer-foot {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn-answer {
  border: none;
  color: #ffffff !important;
  font-size: 18px;
  font-weight: 700;
  border-radius: 34px;
  background: #47269B;
  padding: 10px 40px;
}
.btn-answer:hover {
  background: #1a0451;
}
.btn-answer.disabled {
  background: #C6BFEA;
}
.selected-answer {
  /* background: #65D176; */
  background: #ADD8E6;
  padding: 14px;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  column-gap: 10px;
}
.selected-answer span {
  color: #ffffff;
}
.selected-answer span.option-letter {
  font-size: 16px;
  font-weight: 700;
}
.review-foot {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}