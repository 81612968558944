.content-wrap {
  width: 100%;
  /* height: calc(100vh - 114px); */
  height: calc(83.4vh);
  background: url("../img/innerheader-bg.svg") no-repeat, #f2f6ff;
  background-position: top left;
  background-size: contain;
  padding: 40px;
}
.inner-wrap {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
}
.card-title {
  color: #47269b;
  font-size: 34px;
  padding: 0 200px;
  font-weight: 500;
  line-height: 46px;
  margin-bottom: 10px;
}
.countdown-txt {
  color: #47269b;
  font-size: 18px;
  margin-bottom: 8px;
}
.steps-label {
  color: #47269b;
  text-transform: uppercase;
  background: #d6c6ff;
  padding: 4px 20px;
  border-radius: 30px;
  font-weight: 600;
  margin: 20px 0 30px 0;
}
.countdown-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
}
.countdown-wrap .box {
  min-width: 100px;
}
.countdown-wrap .box .value {
  color: #47269b;
  font-size: 38px;
  font-weight: 600;
  padding: 20px;
  margin-bottom: 10px;
  border: 2px solid #dcceff;
  border-radius: 11px;
}
.countdown-wrap .box .label {
  color: #47269b;
  font-size: 16px;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .card-title {
    color: #47269b;
    font-size: 24px;
    padding: 0px;
    font-weight: 500;
    line-height: 28px;
  }
  .countdown-wrap {
    column-gap: 10px;
  }
  .countdown-wrap .box {
    min-width: 60px;
  }
  .countdown-wrap .box .value {
    color: #47269b;
    font-size: 22px;
    padding: 8px;
  }
}
